import React from "react";
import ArticleCard from "../card/articleCard/articleCard";

import "./lastArticles.scss";
import Button from "../button/button";

import defaultPhoto from "../../images/articles/photo-article-default.webp";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
SwiperCore.use([Navigation]);


export default function LastArticles({ articles }) {
  return (
    <>
    <section className="article__list_section">
          <div className="article__list_page">
          {articles.slice(0, 3).map((article, i) => {
            return (
              <div key={i}>
                <ArticleCard
                  size="small"
                  title={article.Titre}
                  date={article.Date_De_Publication}
                  slug={article.Slug}
                  type="article"
                  img={
                    article.Image_Vignette
                      ? article.Image_Vignette.localFile
                      : defaultPhoto
                  }
                />
              </div>
            );
          })}
          </div>
          <Button
            className="last-articles__btn"
            text="Plus d'articles"
            to="/articles/"
          />
    </section>
    <section className="article__mobile-swiper">
      <Swiper
          slidesPerView={1}
          spaceBetween={1}
          centeredSlides={true}
          roundLengths={true}
          navigation={true}
          loop={true}
        >
          {articles.splice(0, 3).map((article, i) => (
            <SwiperSlide key={i}>
              <ArticleCard
                  size="small"
                  title={article.Titre}
                  date={article.Date_De_Publication}
                  slug={article.Slug}
                  type="article"
                  img={
                    article.Image_Vignette
                      ? article.Image_Vignette.localFile
                      : defaultPhoto
                  }
                />
            </SwiperSlide>
          ))}
        </Swiper>
        <Button
            className="last-articles__btn"
            text="Plus d'articles"
            to="/articles/"
          />
    </section>
    </>
  );
}
