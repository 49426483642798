import React from "react";
import ScrollButton from "../../button/scrollButton/scrollButton";

import "./headerHome.scss";

export default function HeaderHome() {
    return (
        <header className="header-home" id="header">
            <div className={'header-home-background'}>
                <div className={'bg-left'}></div>
                <div className={'bg-right'}></div>
            </div>

            <div className={'header-home-content'}>
                <h1 className="u-mb-big">
                    Nous sommes L’Auxiliaire, assureur partenaire des professionnels
                    du BTP
                </h1>
                <p>
                    Parler le même langage, c’est l’assurance de se comprendre.
                    Depuis notre création, notre vocation est d’apporter sérénité et sécurité aux acteurs de la construction.
                </p>
            </div>

            <ScrollButton headerElemId='header' className="header-home-btn"/>
        </header>
    );
}
