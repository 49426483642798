import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import {GatsbyImage} from "gatsby-plugin-image";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import BlocImage from "../components/dynamicZone/blocImage/blocImage";
import HeaderHome from "../components/header/headerHome/headerHome";
import JobLine from "../components/jobLine/jobLine";
import imgBlocImage from "../images/home/img-home-bloc-image.webp";
import LastArticles from "../components/lastArticles/lastArticles";
import Annonce from "../components/annonce/annonce";

import "./style.scss";
import ReactMarkdown from "react-markdown";
import BackgroundImage from "gatsby-background-image";


const IndexPage = ({ data }) => {
  const metiers = data.allStrapiHome.nodes[0].Metiers;
  const slider = data.allStrapiHome.nodes[0].Slider;
  const lastArticles = data.lastArticles.nodes;
  const produits = data.allStrapiProduit.nodes;
  const annonce = data.allStrapiAnnonce.nodes[0];

  metiers.forEach((metier) => {
    metier.produit_slug = produits.find(
      (produit) => produit.strapi_id === metier.Produit.strapi_id
    ).Slug;
  });

  if(slider.Images.length === 1){
    // si on a une seule image dans le slider alors on l'ajoute encore une fois dans la liste pour prevent un bug du Swiper
    slider.Images = [slider.Images[0],slider.Images[0]]; 
  }

  const imagesAfficher = slider.Images.filter((i) => i.AfficherSiteInternet);

  return (
    <>
      <Helmet>
        <body className="index" />
      </Helmet>
      <Layout>
        <SEO newTitleTemplate="%s · Assurance BTP" title="L'Auxiliaire" description="Architecte, entreprise du bâtiment, ingénieur, artisan, fabricant, négociant, promoteur, L'Auxiliaire, l'assurance des professionnels du bâtiment et des travaux publics."/>
        <Annonce annonce={annonce} />
        <HeaderHome />
        <section className="index-section index-section__assurances">
          <h2 className="index-section__heading">
            <strong>Des assurances</strong> de haute <strong>technicité</strong>
            <br />
            pour tous les métiers <strong>du BTP</strong>&nbsp;!
          </h2>
          <JobLine metiers={metiers} />
        </section>

        { imagesAfficher.length > 0 && (
          <section className="index-section-slider">
            <h2 className="index-section-headline">
              <ReactMarkdown children={slider.Titre} />
            </h2>
            {imagesAfficher.length > 1 ? (
              <Swiper
                slidesPerView={3}
                spaceBetween={1}
                centeredSlides={true}
                navigation={true}
                loop={true}
              >
                {imagesAfficher.map((image, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div>
                        {image.Image.localFile && (
                          <a {...(image.Url !== " " && image.Url != null) ? { href: image.Url } : {}}>
                            <GatsbyImage
                              image={image.Image.localFile.childrenImageSharp[0].gatsbyImageData}
                              alt={``}
                              key={index}
                            />
                          </a>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <div className="center-fixed-image-slider">
                {imagesAfficher[0].Image.localFile && (
                  <a {...(imagesAfficher[0].Url !== " " && imagesAfficher[0].Url != null) ? { href: imagesAfficher[0].Url } : {}}>
                    <GatsbyImage
                      image={imagesAfficher[0].Image.localFile.childrenImageSharp[0].gatsbyImageData}
                      alt={``}
                    />
                  </a>
                )}
              </div>
            )}
          </section>
        )}

        <section className="index-section">
          <h2 className="index-section__heading">
            <strong>Être sociétaire</strong> de L’Auxiliaire
          </h2>
          <BlocImage
            title="<strong>Être proches, c’est de loin le plus important.</strong>"
            text="8 sociétaires sur 10 nous recommandent (vraiment). Découvrez pourquoi&nbsp;!"
            btnText="Voir les interviews"
            btnUrl="/clients"
            imagePath={imgBlocImage}
            className="interview"
          />
          <BlocImage
            title="<strong>Être proches, c’est de loin le plus important.</strong>"
            text="8 sociétaires sur 10 nous recommandent (vraiment). Découvrez pourquoi&nbsp;!"
            btnText="Les interviews"
            btnUrl="/clients"
            imagePath={imgBlocImage}
            className="interview__mobile"
          />
        </section>

        <section className="index-section">
          <h2 className="index-section__heading">
            <strong>Nos </strong> dernières <strong>actualités</strong>&nbsp;!
          </h2>
          <LastArticles className="index-section" articles={lastArticles} />
        </section>
        <section className="index-section index-section__3-boxes">
          <h2 className="index-section__heading index-section__3-boxes__title">
          L’Auxiliaire,<br/>du sens <strong>à l’ouvrage</strong>
          </h2>
          <div className="index-section__3-boxes__content">
            <BackgroundImage
              Tag="section"
              fluid={data.boxImage1.childImageSharp.fluid}
              preserveStackingContext
            >
              <a href="/nos-valeurs">Nos valeurs</a>
            </BackgroundImage>
            <BackgroundImage
              Tag="section"
              fluid={data.boxImage2.childImageSharp.fluid}
              preserveStackingContext
            >
              <a href="/nos-engagements-environnementaux">Préserver demain</a>
            </BackgroundImage>
            <BackgroundImage
              Tag="section"
              fluid={data.boxImage3.childImageSharp.fluid}
              preserveStackingContext
            >
              <a href="/offres-emploi">Nous rejoindre</a>
            </BackgroundImage>
          </div>
        </section>
        <section className="index-section index-section__3-boxes-mobile">
          <h2 className="index-section__heading index-section__3-boxes__title">
          L’Auxiliaire,<br/>du sens <strong>à l’ouvrage</strong>
          </h2>
          <Swiper
            slidesPerView={3}
            spaceBetween={1}
            centeredSlides={true}
            initialSlide={1}
            loop={true}
          >
            <SwiperSlide>
              <BackgroundImage
                Tag="section"
                fluid={data.boxImage1.childImageSharp.fluid}
                preserveStackingContext
              >
                <a href="/nos-valeurs">Nos valeurs</a>
              </BackgroundImage>
            </SwiperSlide>
            <SwiperSlide>
              <BackgroundImage
                Tag="section"
                fluid={data.boxImage2.childImageSharp.fluid}
                preserveStackingContext
              >
                <a href="/nos-engagements-environnementaux">Préserver demain</a>
              </BackgroundImage>
            </SwiperSlide>
            <SwiperSlide>
              <BackgroundImage
                Tag="section"
                fluid={data.boxImage3.childImageSharp.fluid}
                preserveStackingContext
              >
                <a href="/offres-emploi">Nous rejoindre</a>
              </BackgroundImage>
            </SwiperSlide>
          </Swiper>
        </section>
      </Layout>
    </>
  );
};

export const query = graphql`
  query HomePageQuery {
    boxImage1: file(relativePath: { eq: "home/valeurs.webp" }) {
      childImageSharp {
        fluid(
          quality: 90,
          maxWidth: 600
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    boxImage2: file(relativePath: { eq: "home/preserver.webp" }) {
      childImageSharp {
        fluid(
          quality: 90,
          maxWidth: 600
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    boxImage3: file(relativePath: { eq: "home/rejoindre.webp" }) {
      childImageSharp {
        fluid(
          quality: 90,
          maxWidth: 600
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allStrapiHome {
      nodes {
        Metiers {
          Titre
          Image_De_Couverture {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          Produit {
            strapi_id
          }
        }
        Slider {
          Titre
          Images {
            Url
            AfficherSiteInternet
            Image {
              localFile {
                childrenImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    }

    lastArticles: allStrapiArticle(
      sort: { fields: [Important, Date_De_Publication], order: [DESC, DESC] }
      limit: 3
      filter: {Indexer: {eq: true}}
    ) {
      nodes {
        Important
        Titre
        Slug
        Date_De_Publication(formatString: "DD/MM/YYYY")
        Image_Vignette {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }

    allStrapiProduit {
      nodes {
        Slug
        strapi_id
      }
    }

    allStrapiAnnonce {
      nodes {
        Titre
        Message {
          data {
            Message
          }
        }
        Afficher
      }
    }
  }
`;

export default IndexPage;
