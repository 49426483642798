import React, {useEffect, useState} from "react";

import "./annonce.scss";

import closeIcon from "../../images/annonce/close-colored.svg";
import InfoIcon from "../../images/annonce/info.svg";
import ReactMarkdown from "react-markdown";



export default function Annonce({ annonce }) {
  
  

  const [showAnnonce, setShowAnnonce] = useState(annonce.Afficher);

  useEffect(()=>{
    if(annonce.Afficher){
      setShowAnnonce(sessionStorage.getItem('annonce') || true);
    }
  }, []);

  const closeAnnonce = () => {
    sessionStorage.setItem('annonce', false);
    setShowAnnonce(false);
  }

  return (
    <div>
      {showAnnonce === true && 
        <div className="annonce">
          <img src={InfoIcon} alt="icone information" />
          <div className="annonce__text">
            <p className="annonce__text--title"><strong>{annonce.Titre}</strong></p>
            <ReactMarkdown
              className="annonce__text--message"
              children={annonce.Message.data.Message}
            />
          </div>
          <img className="annonce__close" src={closeIcon} onClick={() => closeAnnonce() } alt="icone croix" />
        </div>
      }
    </div>
  );
}
